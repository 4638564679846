import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nod-as-national-platform"
    }}>{`NOD as national platform`}</h1>
    <p>{`The primary task of NOD is to distribute an Order to a Ticket Medium (physical or electronic). NOD offers functionality specified in `}<a parentName="p" {...{
        "href": "https://www.jernbanedirektoratet.no/dokumenter/?_search=v821&_attachment_categories=handboker"
      }}>{`Handbook V821`}</a>{` in such a way that users do not have to familiarize themselves with the technical details of the specification:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A PTO can relate to a Logical Order. There is no need to know anything about how an Order is physically distributed to the Ticket Media.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A NOD Client may relate to a few generic NOD Client commands, such as "View Message" or "View Mobile Ticket". A client will therefore be able to support Pickup by an Order Group regardless of which PTO has distributed the order, or what the Order actually contains.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Neither the PTO nor the NOD Client need to know the security solutions used to protect the Ticket Media and its contents.`}</p>
      </li>
    </ul>
    <p>{`The NOD platform has the opportunity to make the sales and distribution channels completely independent of each other. One can have a blend of national and specialized sales solutions in combination with a rich selection of NOD Clients owned by various players, including clients the Traveler owner himself (the Traveler cell phone).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      